// Base CSS Variable colors
:root {
  --font: "Inter", Helvetica, Arial, sans-serif;
  --fontPrice: "D-DIN";

  --brandColor: #8fc852;
  --secondaryColor: #f28100;
  --brandColorDarken: #{darken(#8fc852, 10%)};
  --brandColorContrast: #fff;
  --linkColor: var(--brandColor);
  --borderColor: #ececec;

  --statusDanger: #f05628;
  --statusSuccess: #8fc852;

  --addToCartIconBuy: "\e905";
  --addToCartButtonTextColor: var(--brandColorContrast);
  --addToCartButtonBgColor: var(--brandColor);
  --addToCartButtonBorderColor: var(--addToCartButtonBgColor);

  --addToCartButtonToggleTextColor: var(--brandColorContrast);
  --addToCartButtonToggleBgColor: var(--brandColor);
  --addToCartButtonToggleBorderColor: var(--addToCartButtonBgColor);

  --addToCartButtonInCartTextColor: var(--brandColorContrast);
  --addToCartButtonInCartBgColor: var(--secondaryColor);
  --addToCartButtonInCartBorderColor: var(--addToCartButtonInCartBgColor);

  --addToCartButtonTextColorHover: var(--brandColorContrast);
  --addToCartButtonBgColorHover: var(--brandColorDarken);
  --addToCartButtonBorderColorHover: var(--addToCartButtonBgColorHover);

  --modalOverlay: #{darken(#8fc852, 1%)};

  --brandColor: #ff4043;
  --secondaryColor: #e30a17;
  --brandColorDarken: #cd0e11;
  --errorColor: #dc3545;

  --addToCartIconBuy: "\e908";

  --addToCartButtonInCartTextColor: #fff;
  --addToCartButtonInCartBgColor: #1a1a1a;
  --addToCartButtonInCartBorderColor: #1a1a1a;

  --addToCartButtonTextColorHover: #ff4043;
  --addToCartButtonBgColorHover: #fff;
  --addToCartButtonBorderColorHover: #ff4043;
}

//	Grid
$w-desktop: 1280px;
$w-tablet: 920px;
$w-mobile: 680px;

$w-site: 1600px;
$w-page: 1558px;

$w-space: 0.8rem;

//	Colors
$brandColor: var(--brandColor);
$primaryColor: #6db991;
$errorColor: #dc3545;

$body-bgColor: #f7f7f7;

$textColor: #1a1a1a;
$textColor--muted: #7d7d7d;
$textColor--onBrand: #fff;

$linkColor: var(--linkColor);

$border: var(--borderColor);
$borderColor: $border;
$borderColor--darken: #7d7d7d;
$borderRadius: 4px;

$boxShadow-color: rgba(0, 0, 0, 0.2);
$boxShadow-color--light: rgba(0, 0, 0, 0.1);

$status--danger: var(--statusDanger);
$status--success: var(--statusSuccess);

//	Type
$font: var(--font);
$font--title: $font;
$font--price: var(--fontPrice);

$fontSize: 15px;
$fontSize--min: 13px;
$fontSize--max: 18px;

$fontSize--down-1: 0.93333em;
$fontSize--down-2: 0.866666em;
$fontSize--down-3: 0.8em;
$fontSize--up-1: 1.133em;
$fontSize--up-2: 2.667em;
$fontSize--up-3: 3.133em;
$fontSize--up-med: 1.8em;

$fontWeight--light: 300;
$fontWeight--normal: 400;
$fontWeight--medium: 500;
$fontWeight--semibold: 600;
$fontWeight--bold: 700;

// Icons
$iconFont: "UI-Icons";
$icon--left: "\ea1b";
$icon--right: "\ea1c";
$icon--up: "\ea1d";
$icon--down: "\ea1e";
$icon--add: "\ea2f";
$icon--remove: "\ea2e";
$icon--checkbox: "\ea75";
$icon--checkboxChecked: "\ead4";
$icon--radio: "\ea78";
$icon--radioChecked: "\e901";
$icon--search: "\ea3d";
$icon--closeSmall: "\ea29";
$icon--removeSmall: "\ea2c";
$icon--favourite: "\ea76";
$icon--favouriteChecked: "\e907";

// Block
$block-bgColor: #fff;
$block-padding: 2rem;
$block-padding--vert: 1.5rem;
$block-padding--body: 3.45rem;

// Header
// Header Navbar
$headerNavbar-bgColor: #394143;
$headerNavbar-textColor: #fff;
$headerNavbarCol-width: 10rem;

// Header Tools
$headerTools-bgColor: $block-bgColor;

//	Footer
$footer-bgColor: #394143;
$footer-textColor: #fff;
$footer-linkColor: #fff;

// Navpanel
$navPanel--width: 13.667rem;
$navPanel-animationTime: 0.25s;
$navPanelLink-textColor: $textColor;
$navPanelLink--hover-textColor: $brandColor;
$navPanelLink--muted-textColor: $textColor--muted;
$navPanelLink--selected-textColor: $brandColor;

//	Cartpanel
$cartPanel--width: 16.667rem;
$cartPanel-animationTime: 0.25s;
$cartPanelLink-textColor: $textColor;
$cartPanelLink--hover-textColor: $brandColor;
$cartPanelLink--muted-textColor: $textColor--muted;
$cartPanelLink--selected-textColor: $brandColor;

//	Breadcrumbs
$breadcrumbs-textColor: $textColor--muted;
$breadcrumbs-linkColor: $textColor--muted;

// Product
$productCard-space: 3.5rem;

$productListItem--width: 13.667rem;
$productListItem-bgColor: $block-bgColor;
$productListItem-borderColor: $block-bgColor;
$productListItem--hover-borderColor: $borderColor;
$productListItem-discount-textColor: $textColor--muted;
$productListItemFavourite-textColor: #c1c1c1;
$productListItemFavourite-textColor--isActive: $brandColor;

// Add to cart
$addToCart-borderRadius: 4px;
$addToCart-icon--buy: var(--addToCartIconBuy);
$addToCartButton-textColor: var(--addToCartButtonTextColor);
$addToCartButton-bgColor: var(--addToCartButtonBgColor);
$addToCartButton-borderColor: var(--addToCartButtonBorderColor);

$addToCartButton--hover-textColor: var(--addToCartButtonTextColorHover);
$addToCartButton--hover-bgColor: var(--addToCartButtonBgColorHover);
$addToCartButton--hover-borderColor: var(--addToCartButtonBorderColorHover);

$addToCartButton--toggle-textColor: var(--addToCartButtonToggleTextColor);
$addToCartButton--toggle-bgColor: var(--addToCartButtonToggleBgColor);
$addToCartButton--toggle-borderColor: var(--addToCartButtonToggleBorderColor);

$addToCartButton--inCart-textColor: var(--addToCartButtonInCartTextColor);
$addToCartButton--inCart-bgColor: var(--addToCartButtonInCartBgColor);
$addToCartButton--inCart-borderColor: var(--addToCartButtonInCartBorderColor);

$addToCartInput-borderColor: #e6e6e6;
$addToCart-animationTime: 0.45s;

// 	Forms
$input-borderColor: #e6e6e6;
$input-borderColor--focus: darken($input-borderColor, 10%);
$input-borderRadius: 4px;
$input-padding: 0.5em;
$input-placeholder-color: #868686;
$form-space: 0.65em;

// 	Buttons
$button-borderColor: #c1c1c1;
$button-padding: 0.65em 0.833em;

//	Modal
$modal-overlay: var(--modalOverlay);

// Screen Sizes
$screen-sm-min: 400px;
$screen-md-max: 767px;
// Small tablets (portrait view)
$screen-md-min: 768px;
// Tablets and small desktops
$screen-lg-min: 992px;
// Large tablets and desktops
$screen-xl-min: 1200px;
