@mixin datatable {
  .datatable-body-cell-label {
    a {
      text-decoration: none;
    }
  }
  @include screen-below(sm) {
    .dt-header-content {
      padding: 0 0.5em;
      form {
        &.form-inline {
          &.actions {
            width: 100%;
          }
        }
      }
      &__main {
        padding: 0;
      }
      &__title {
        padding: 0;
      }
      .dt-filter-self {
        margin-left: 0.5em;
        width: 90%;
        margin-top: 0.65em;
        margin-bottom: 0.65em;
      }
      .heading {
        margin-left: 0.3em;
      }
      .dt-filter {
        width: 100%;
        &__input {
          width: 45%;
          margin-top: 0.65em;
          margin-bottom: 0.65em;
          margin-right: 1em;
          &--text {
            width: 100%;
          }
          &--stretch {
            width: 90%;
            input {
              width: 100%;
            }
          }
          ng-select {
            min-width: auto !important;
            width: 100% !important;
          }
          input {
            &.date {
              width: 100% !important;
            }
          }
        }
        &__action {
          align-items: center;
          margin-top: 0.65em;
          margin-bottom: 0.65em;
          button {
            width: 6em;
            margin-right: 0.75em;
            border-radius: 0.25rem !important;
            padding: 0.25em;
          }
        }
      }

      .dt-header-btn {
        margin-left: 0.4em;
        margin-right: 1em;
        display: flex;
        width: 100%;
        flex-direction: row-reverse;
        &__add {
          width: 6em;
          padding: 0.25em;
        }
      }
    }
    datatable-footer {
      .datatable-footer-inner {
        flex-direction: column;
        height: 65px !important;
        padding: 5px 0px;
        .page-count {
          padding: 0 10px !important;
          height: 25px !important;
          line-height: 25px !important;
          display: flex;
          align-items: center;
        }
        .datatable-pager {
          ul.pager {
            margin: 5px 0;
            li {
              margin: 3px 0px !important;
            }
          }
        }
      }
    }
  }
}
