@include datatable;
@import "./variables";

@include screen-below(sm) {
  nav {
    &.navbar {
      @include hamburger-menu;
      position: fixed;
      width: 100%;
      z-index: 101;
      top: 0;

      .dropdown-menu {
        &.profile-menu {
          margin-top: 1em;
          margin-bottom: 0.5em;
          .dropdown-item {
            font-size: 0.9rem;
            font-weight: 500;
            padding: 0.25rem 1.2rem;
          }
          .dropdown-divider {
            margin: 0.2rem 0;
          }
        }
      }

      .navbar-brand {
        font-size: 0.85rem;
        img {
          width: 7.8em;
        }
      }
      .account-btn {
        padding: 0.15rem 0.5rem;
        font-size: 0.8rem;
      }
      .nav-mobile-menu-btn {
        font-size: 1.1rem;
        i {
          color: $primaryColor;
        }
      }
    }
  }
  .layout {
    #content {
      padding: 0.5em !important;
      margin-top: 3.5em;
    }
    .wrapper--layout {
      display: flex;
      flex-direction: column;
    }

    sv-sidebar {
      nav {
        &#sidebar {
          position: fixed;
          z-index: 100;
          margin-top: 3.5em;
          background-color: $primaryColor !important;
          border-right: 1px solid #cfe9ee !important;
          h4 {
            display: none;
          }
          ul {
            li {
              a {
                border-bottom: 1px solid rgb(238 238 238) !important;
                font-size: 11px !important;
              }
            }
          }
          &.mb-sidebar {
            &--active {
              display: block;
            }
            &--enter {
              display: block;
            }
          }
        }
      }
    }

    .product-list,
    .display-group,
    .supplier-list,
    .order-list,
    sv-list,
    sv-write > div:nth-child(1) {
      margin: 0em 0.5em 0 0.5em !important;
    }
    sv-write {
      .card-body {
        padding: 0.9rem;
      }
    }
    .sv--details {
      margin: 0 0.5em !important;
    }

    .dashboard {
      .report-card {
        display: flex;
        flex-direction: column;
        &__action {
          margin-bottom: 1em;
        }
        &__chart {
        }
      }
    }
  }
}

@include screen-above(sm) {
  .layout {
    nav {
      &.navbar {
        .nav-mobile-menu-btn {
          &.side-menu-btn {
            display: none;
          }
        }
      }
    }
    .wrapper--layout {
      display: flex;
      align-items: stretch;
    }
    .filter-row {
      padding-bottom: 0.5em;
      align-items: flex-end;

      .form-group {
        label {
          font-weight: 600;
        }
      }
      .action-btn {
        margin-bottom: 1rem;
      }
    }
    sv-dropdown {
      &.ng-invalid {
        &.ng-touched {
          .ng-select {
            .ng-select-container {
              border-color: $errorColor;
            }
          }
        }
      }
    }
  }
}

@include screen-above(md) {
  .layout {
    nav {
      &.navbar {
        .nav-mobile-menu-btn {
          &.account-btn {
            display: none;
          }
        }
      }
    }
  }
  .dg-selectbox {
    .ng-optgroup,
    .ng-option-child {
      font-size: 14px;
    }
    &--sm {
      &.ng-select {
        .ng-select-container {
          height: 31px;
          min-height: 31px;
        }
      }
    }
  }
}
