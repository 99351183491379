/* You can add global styles to this file, and also import other style files */
@import "open-iconic/font/css/open-iconic-bootstrap.css";
@import "ngx-toastr/toastr";
@import "@ng-select/ng-select/themes/default.theme.css";
@import "@ali-hm/angular-tree-component/css/angular-tree-component.css";

@import "./mixins/core.mixin";
@import "./variables";

@import "./custom";

body {
  overflow-x: hidden;
  .btn-primary {
    background-color: #6db991;
    border-color: #6db991;
    &:disabled,
    &:focus,
    &:active {
      background-color: #6db991;
      border-color: #6db991;
    }
    &:hover {
      background-color: #99a192;
      border-color: #99a192;
    }
  }
  .btn {
    &.btn-label {
      position: relative;
      padding-left: 40px;
      font-weight: 500;
      i {
        position: absolute;
        width: 32px;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(255, 255, 255, 0.1);
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .hidden {
    display: none;
  }
  label {
    &.required:after {
      content: " *";
    }
  }
  .date {
    background-color: #ffffff !important;
    cursor: pointer;
  }
  .date-time-picker {
    .row {
      display: flex;
      flex-wrap: wrap;
    }
    .dl-abdtp-day-view,
    .dl-abdtp-month-view,
    .dl-abdtp-year-view,
    .dl-abdtp-hour-view,
    .dl-abdtp-minute-view {
      position: absolute;
      background-color: #ffffff;
      border: 1px solid #dfdfdf;
      z-index: 9999;
      width: 100%;
    }
    .col {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }

    .text-center {
      text-align: center;
    }

    .align-items-center {
      align-items: center !important;
    }

    .no-gutters {
      margin-right: 0;
      margin-left: 0;
    }

    .dl-abdtp-col-label,
    .dl-abdtp-view-label {
      padding: 0.5em !important;
      text-align: center !important;
      font-weight: bold !important;
      border-top: 1px solid #dfdfdf !important;
      border-bottom: 1px solid #dfdfdf !important;
      margin-bottom: 0.5em !important;
      font-size: 14px !important;
    }
    .dl-abdtp-date-button,
    .dl-abdtp-left-button,
    .dl-abdtp-right-button,
    .dl-abdtp-view-label {
      cursor: pointer !important;
      padding: 0.75em 0 !important;
      text-align: center !important;
      border: 0 !important;
      background: none !important;
    }

    .dl-abdtp-selected {
      background: #ff4043 !important;
      color: #ffffff !important;
      border-radius: 0px !important;
    }

    .dl-abdtp-left-button,
    .dl-abdtp-right-button {
      span {
        @extend [class^="icon-"] !optional;
      }
    }
  }
  tooltip {
    font-size: 0.87rem !important;
    //background-color: #6db991 !important;
    padding: 3px 6px !important;
    /* &::after {
      border-color: #6db991 transparent transparent !important;
    } */
  }
  .modal-holder {
    animation-name: example;
    animation-duration: 0.3s;
  }

  @keyframes example {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }

  .badge {
    &.dropdown-badge {
      position: relative;
      top: -4px;
      font-size: 65%;
      letter-spacing: 0.6px;
    }
  }

  //ngx-toastr
  .toast-container {
    .ngx-toastr {
      background-position-y: 18px;
    }
  }

  .faded {
    opacity: 0.5;
    pointer-events: none;
  }

  .chkbox-inline {
    display: inline-flex;
    align-items: center;
    input[type="checkbox"] {
      margin-right: 0.28em;
      margin-top: 0.05em;
    }
  }

  .dg-selectbox {
    .dg-title {
      display: inline-flex;
      align-items: baseline;

      .code {
        margin-right: 0.3em;
        &::after {
          content: "|";
          margin-left: 0.1em;
          position: relative;
          bottom: 0.12em;
        }
      }

      .default-checked {
        color: #299b29;
        position: relative;
        margin-left: 0.2em;
      }
    }
    .ng-dropdown-panel {
      .ng-option.ng-option-child,
      .ng-optgroup {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 16px;
        white-space: normal;

        .dropdown-badge {
          margin-left: 0.3em;
          top: 0;
        }
      }
    }
    .ng-value {
      .dropdown-badge {
        margin-left: 5px;
      }
    }
  }

  .displaygroup-col {
    .displaygroup__title {
      display: inline-flex;
      align-items: baseline;

      .code {
        &::after {
          margin-left: 0.1em;
          position: relative;
          bottom: 0.12em;
        }
      }
      .separator {
        margin-left: 0.1em;
        margin-right: 0.1em;
      }
    }
  }

  .full-width-error-toastr {
    width: 400px !important;
  }

  .ng-select {
    &.ng-select-disabled {
      > .ng-select-container {
        background-color: #e9ecef;
      }
    }
  }
}

/*** ======= Start of Bootstrap 4 compatibility classes ===== ***/

.form-group {
  margin-bottom: 1rem;
}

select.form-control:read-only {
  background-color: transparent !important;
}

label {
  margin-bottom: 0.5rem;
}

.input-group-text {
  height: 100%;
}
.input-group-append,
.form-inline {
  display: flex;
}
$spacing-map: (
  0: 0,
  1: 0.25rem,
  2: 0.5rem,
  3: 1rem,
  4: 1.5rem,
  5: 3rem,
);
@mixin generate-margin-padding-classes($map) {
  // Generate margin-right and margin-left classes
  @each $key, $value in $map {
    .mr-#{$key} {
      margin-right: #{$value} !important;
    }
    .ml-#{$key} {
      margin-left: #{$value} !important;
    }
  }

  // Generate padding-right and padding-left classes
  @each $key, $value in $map {
    .pr-#{$key} {
      padding-right: #{$value} !important;
    }
    .pl-#{$key} {
      padding-left: #{$value} !important;
    }
  }
}

// Use the mixin to generate classes
@include generate-margin-padding-classes($spacing-map);

.badge {
  &.badge-warning {
    background-color: #ffc107 !important;
  }

  &.badge-danger {
    background-color: #dc3545 !important;
  }

  &.badge-primary {
    background-color: #007bff !important;
  }

  &.badge-secondary {
    background-color: #6c757d !important;
  }

  &.badge-success {
    background-color: #28a745 !important;
  }

  &.badge-info {
    background-color: #17a2b8 !important;
  }

  &.badge-light {
    background-color: #f8f9fa !important;
  }

  &.badge-dark {
    background-color: #343a40 !important;
  }
}

/*** ======= End of Bootstrap 4 compatibility classes ===== ***/

ngb-tooltip-window {
  .tooltip-inner {
    font-size: 13px;
  }
}

input.form-control[readonly] {
  background-color: #f8f8f8;
}

input.form-control[disabled] {
  // background-color: #edebeb !important;
  background-color: #f8f8f8 !important;
}
